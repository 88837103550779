a{
    text-decoration: none;
    color: black;
}

.confirm-btn{
    width: 100%;
    background-color: rgba(102, 255, 0, 0.836);
    border: none;
    font-size: 1.1rem;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.confirm-btn:disabled{
    background-color: rgb(185, 181, 181);
    cursor: not-allowed;
}

.btn{
    border: none;
    font-size: 1.1rem;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

