.MilUnit-main-div{
    width: 50vw;
    height: 50vh;
    background: rgb(65, 138, 255);
    border-radius: 25px;
    display: flex;
    justify-content: center;
}
.MilUnit-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}
.MilUnit-div input{
    margin-bottom: 10px;
    width: 100%;
    font-size: 16px;
}
.MilUnit-div{
    display: flex;
    align-items: center;
    width: 80%;
    font-size: 20px;
}
.MilUnit-div button {
    width: 60%;
    font-size: 20px;
    margin-top: 10px;
}

.editing-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(7, 6, 6, 0.829);
}

.modal {
    display: block;
    padding: 0 30px;
    padding-bottom: 30px;
    background-color: white;
    height: fit-content;
}

.button-group {
  display: flex;
  justify-content: center;
}

.button-view {
    border-radius: 10px;
    padding: 10px 25px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
}

.button-view:last-child {
    margin-right: 0;
}

.editing-input{
    margin-top: 20px;
}

.editing-input input{
    width: calc(100% - 20px) ;
    border-radius: 10px;
    border: 1px solid grey;
    padding: 10px;
    margin-bottom: 15px;
}

.add{
    background-color: rgba(102, 255, 0, 0.836);
}

.close{
    background-color: rgba(255, 38, 0, 0.836);
    color: antiquewhite;
}
