.profile-div{
    display: flex;
    align-items: center;
    padding: 15px 10px 5px 10px;
}

.profile-div img{
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}