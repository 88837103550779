.BaseList-main-div{
    width: 100%;
    height: 7vh;
}
.BaseList-header-div{
    display: flex;
    font-size: 22px;
    justify-content: space-evenly;
}
.BaseList-header{
    width: 100%;
    border-right: 1px solid rgb(170, 166, 166);
    border-bottom: 1px solid rgb(170, 166, 166);
    background-color: rgb(230, 228, 228);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    cursor: pointer;
}

.BaseList-header:hover{
  background-color: rgb(201, 209, 207);
}

.BaseList-header button{
  width: 100%;
  height: 100%;
  border: none;
  background: rgba(250, 255, 247, 0.5);
  padding: 0;
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
  cursor: pointer;
}

.active-tab{
  background-color: rgb(184, 243, 223);
}

.active-tab button{
  background: radial-gradient(ellipse at center, rgba(14, 27, 74, 0.603), transparent 50%);
  color: white;
  font-weight: bold;
  border-bottom: white;
}

.active-tab:hover{
  background-color: rgb(184, 243, 223);
}

.BaseList-header:last-child{
  border-right: none;
}

.BaseList-body-div{
    width: 100%;
}

.View-Correct-main-div{
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.View-Correct-main-div h2{
  margin: 20px 0;
}
.Correct-Mode-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.Correct-Mode-select{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    border-style: solid none;
    font-size: 22px;
}

.Correct-Mode-select div:nth-child(even){
    border-right: 1px solid black;
    border-left: 1px solid black;
}
.Correct-Mode-select div {
    width: calc(100% / 3);
    text-align: center;
}

.Correct-Mode-select div:hover {
    background-color: gray;
  }

.Selection-window {
    width: 100%;
    height: 100%;
    background-color: white;
    text-align: center;
    overflow: auto;
  }

  .mode-option {
    height: calc(1.2rem + 30px);
    background: url("/public/button.webp");
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .mode-option:hover {
    background-color: gray;
  }

  .editing-list {
     border: 1px solid gray;
  }

  .editing-list button:hover{
    background-color: rgb(113, 143, 44);
    color: white
  }

  .mode-option button{
    width: 100%;
    height: 100%;
    border: none;
    background: rgba(250, 255, 247, 0.5);
    padding: 0;
    font-size: 1.2rem;
    color: black;
    font-weight: bold;
  }

  .selected button{
    background: radial-gradient(ellipse at center, black, transparent 80%);
    color: white;
    font-weight: bold;
    border-bottom: white;
  }

  .editing-list-conteiner{
    padding: 10px;
  }

  .editing-list-conteiner h2{
    padding: 15px 0 20px 0;
  }

  .editing-list-conteiner h3{
    position: relative;
    padding: 15px;
    border-bottom: 1px solid gray;
  }

  .add-buttons{
    display: flex;
    justify-content: end;
    padding: 10px;
    border-bottom: 1px solid gray;
  }

  .add-buttons button{
    font-size: 0.9rem;
    padding: 5px 10px;
    border: 1px solid gray;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .add-buttons button:last-child{
    margin-right: 0;
  }

  .active{
    background: rgb(113, 143, 44);
    color: white;
  }

  .print-btn{
    position: fixed;
    bottom: 25px;
    right: 25px;
    padding: 10px 15px;
    background: rgb(32, 32, 131);
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }

  .units{
    width: calc(100% - 30px);
    border: 1px solid grey;
  }

  .each-unit{
    width: 100%;
  }

  .unit-number{
    border-bottom: 1px solid gray;
    padding: 10px 15px;
    background-color: rgba(128, 128, 128, 0.479);
  }

  .each-number{
    border-bottom: 1px solid gray;
    padding: 10px 15px;
    background-color: rgba(207, 203, 203, 0.315);
  }

  .adding{
    position: absolute;
    z-index: 2;
    padding: 5px;
    border: 2px solid black;
    border-radius: 5px;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  @media print {
    *::-webkit-scrollbar{
      display: none;
    }

    .print-btn{
      display: none;
    }

    .units{
      border: 1px solid grey !important;
    }
  
    .unit-number{
      border-bottom: 1px solid gray !important;
      background-color: rgba(128, 128, 128, 0.479) I !important;
    }
  
    .each-number{
      border-bottom: 1px solid gray !important;
      background-color: rgba(207, 203, 203, 0.315) !important;
    }

    .content {
      page-break-inside: auto;
      page-break-before: auto;
      page-break-after: auto;
    }
  }
  

