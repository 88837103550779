*{
    margin: 0;
    padding: 0;
}

.main-div{
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.menu-list{
    width: 20vw;
    height: 100vh;overflow: auto;
    border-right: 1px solid gray;
}

.parts{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

@media print{
    .menu-list{
        display: none;
    }
}