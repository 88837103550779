.menu-name{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 10px;
    border-bottom: 1px solid gray;
    cursor: pointer;
}

.choosed{
    background-color: rgba(199, 191, 191, 0.747);
}