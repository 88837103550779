.editing-items {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: 10px 10px;
  text-align: left;
  cursor: pointer;
}

.editing-items:last-child {
  border-bottom: none;
}

.items-controllers{
  display: flex;
}

.move-items-div{
  display: flex;
  padding: 0 10px;
  margin-right: 25px;
  font-size: 1.3rem;
}

.up{
  margin-right: 20px;
  color: green;
}

.down{
  color: rgb(143, 8, 8);
}

.up:hover{
  transform: scale(1.3);
}

.down:hover{
  transform: scale(1.3);
}

.edit{
  margin-right: 15px;
}

.edit:hover{
  transform: scale(1.3);
}

.remove:hover{
  transform: scale(1.3);
}

.editing-items:hover {
  background-color: rgba(224, 220, 220, 0.747);
}