.corpus-main-div{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.corpus-container{
    text-align: center;
    margin: 20px 0;
}

.corpus-main-div h2{
    margin-bottom: 20px;
}

.corpus-container input{
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid gray;
    border-radius: 10px;
    outline: none;
}

.corpus-container div{
    position: relative;
}

.corpus-container label{
    position: absolute;
    top: -10px;
    font-size: .9rem;
    margin-left: 15px;
    padding: 0 5px;
    background-color: white;
}

.corpus-list{
    width: calc(100% - 20px);
    text-align: center;
    height: 100%;
    overflow: auto;
}

ol{
    width: 100%;
}

.corpus-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid gray
}

.corpus-list-item button{
    cursor: pointer;
}

select{
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 10px;
    outline: none;
}

.profile-info-container{
    padding: 15px 25px;
}

.profile-info-container h3{
    margin-bottom: 15px;
}

